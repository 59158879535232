export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lrjt57ot07.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_FugG72XBY",
    APP_CLIENT_ID: "7qp99uls8h7n0d0snsebc40lbi",
    IDENTITY_POOL_ID: "us-west-2:6c524efd-45c5-467e-b322-88fc257dbb50"
  }
};
